<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #fff;
}
a:hover {
  color: #ae0000;
}
html,
body {
  position: relative;
  height: 100%;
}
.swiper-container-pointer-events.swiper-container-vertical {
  -ms-touch-action: none;
  touch-action: none;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: 0.2s transform, 0.2s top;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 0.2s transform, 0.2s left;
}

.swiper-container-horizontal.swiper-container-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 0.2s transform, 0.2s right;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: right top;
}

.swiper-container-rtl
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}

.swiper-pagination-white {
  --swiper-pagination-color: #ffffff;
}

.swiper-pagination-black {
  --swiper-pagination-color: #000000;
}

.swiper-pagination-lock {
  display: none;
}
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.sections {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.ari_pods,
.beats,
.apple_watch,
.galaxy_buds,
.slide5,
.slide6,
.slide7,
.slide8,
.slide9 {
  width: 100%;
  height: 100vh;
  padding: 0 80px;
}

.section_container {
  width: 100%;
  margin: 0 auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ReactBox {
  height: 50vh;
  width: 50vh;

  border: 5px solid rgb(153, 12, 12);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .ReactBox {
    width: 30vh;
    height: 30vh;
  }
}

.GreyBox {
  height: 340px;
  width: 340px;

  border: 5px solid rgb(54, 53, 53);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1px) and (max-width: 300px) {
  .GreyBox {
    height: 50px;
    width: 50px;
  }
}

@media (min-width: 201px) and (max-width: 500px) {
  .GreyBox {
    height: 200px;
    width: 200px;
  }
}
/*
@media (min-width: 201px) and (max-width: 500px) {
  .ReactBox {
    height: 200px;
    width: 200px;
  }
}

@media (min-width: 1px) and (max-width: 200px) {
  .ReactBox {
    height: 50px;
    width: 50px;
  }
}
*/
.ari_pods {
  background-color: #161616;
  color: #fff;
}

/* .beats {
 
  background-image: url("../assets/images/webp/excellence.jpg");
  background-size: cover;
  background-repeat: no-repeat;
   
    background-position-x: center;

 
} */

/* @media screen and (max-width: 600px) {

      .beats {

          background-image: url("../assets/images/slide2.png");
          background-size: cover;
          background-repeat: no-repeat;
          
            background-position-x: center;

      }

        
    }
    */

/* .apple_watch { 
 
    background-image: url("../assets/images/webp/innovation.jpg");
  background-size: cover;
   background-repeat: no-repeat;
   
    background-position-x: center;
} */

/* 
    @media screen and (max-width: 600px) {

      .apple_watch {

          background-image: url("../assets/images/slide3.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          
            background-position-x: center;

      }

        
    } */

.galaxy_buds {
  /* background-color: #00a2e2; */
  background-image: url("../assets/images/webp/ROI.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  background-position-x: center;
}

@media screen and (max-width: 600px) {
  .galaxy_buds {
    background-image: url("../assets/images/roi628x428.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    background-position-x: center;
  }
}

.slide5 {
  /* background-color: #ca112f; */
  background-image: url("../assets/images/webp/partnership.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  /* background-position-x: center; */
}

@media screen and (max-width: 600px) {
  .slide5 {
    background-image: url("../assets/images/slide6.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    background-position-x: center;
  }
}

.slide5-img {
  /* background-image: url("../assets/images/webp/slide5-img-min.webp"); */

  /* background-size: cover;
  background-repeat: no-repeat;
  height: 100%; */
  /* width: 54%; */
}

/* @media (min-width: 1px) and (max-width: 1000px) {
  .slide5-img {
    width: 100vh;
  }
} */
/* @media (min-width: 1px) and (max-width: 500px) { */
/* .slide5 {
    background-image: url("../assets/images/slide5-img.jpg");
    background-size: auto;
  } */
/* } */

.slide6 {
  /* background-image: url("../assets/images/slide5-img.jpg");
  background-size: cover; */
  background-color: #ca112f;
  /* background-image: url("../assets/images/webp/development.jpg"); */

  background-size: cover;

  background-repeat: no-repeat;

  background-position-x: center;
  height: 100%;
}

.slide7 {
  background-image: url("../assets/images/webp/quality.jpg");
  background-size: cover;
  /* background-color: #161616; */
  background-repeat: no-repeat;

  background-position-x: right;
}

.slide8 {
  /* background-image: url("../assets/images/slide8-bg.jpg"); */
  background-image: url("../assets/images/webp/engineering.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  background-position-x: center;
}

@media screen and (max-width: 600px) {
  .slide8 {
    background-image: url("../assets/images/slide8.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    background-position-x: center;
  }
}

.slide9 {
  background-image: url("../assets/images/webp/slide9-bg.webp");
  background-size: cover;
}

img {
  width: 100%;
}

.SliderCenterImg {
  width: 55%;
  position: absolute;
}

@media screen and (min-width: 10px) and (max-width: 767px) {
  .SliderCenterImg {
    width: 92%;
    position: absolute;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .SliderCenterImg {
    width: 71%;
    position: absolute;
  }
}

.SliderCenterImg2 {
  width: 24%;
  position: absolute;
}

@media screen and (min-width: 10px) and (max-width: 766px) {
  .SliderCenterImg2 {
    width: 60%;
    position: absolute;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .SliderCenterImg2 {
    width: 37%;
    position: absolute;
  }
}

.text {
  width: 350px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-align: left;
}

.text > .title {
  font-size: 82px;
  font-weight: 100;
  margin-bottom: 50px;
}

.ari_pods > .section_container > .text {
  color: #fff;
}

.FirstSlideTitle {
  width: 100% !important;
  text-align: center;
  line-height: initial;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  font-family: sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-family: "UnitedSansCondHeavy";
  width: fit-content;
  z-index: 1;
  padding-left: 10%;
  padding-right: 10%;

  text-shadow: 2px 2px #16161638;
}

/*
@media screen and (min-width: 10px) and (max-width: 373px) {
  .FirstSlideTitle {
    width: 110% !important;
    font-size: 10vw;
  }
}

@media screen and (min-width: 374px) and (max-width: 425px) {
  .FirstSlideTitle {
    font-size: 12vw;
    width: 124% !important;
  }
}*/

@media screen and (max-width: 768px) {
  .FirstSlideTitle {
    font-size: 12vw;
  }
}

.SlideTitle {
  width: 100% !important;
  text-align: center;
  line-height: initial;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5.5rem;

  text-transform: uppercase;
  color: #fff;
  font-family: "UnitedSansCondHeavy";
  width: fit-content;
  z-index: 1;
  padding-left: 20%;
  padding-right: 20%;
  text-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8);
}

/* @media (min-width: 200px) and (max-width: 500px) {
    .SlideTitle {
      width: 100%;
      font-size: 1.1875rem;
    }
  } */

@media screen and (min-width: 0px) and (max-width: 373px) {
  .SlideTitle {
    width: 100% !important;
    font-size: 9vw;
  }
}

@media screen and (min-width: 374px) and (max-width: 524px) {
  .SlideTitle {
    font-size: 12vw;
    width: 121% !important;
  }
}

@media screen and (min-width: 525px) and (max-width: 768px) {
  .SlideTitle {
    width: 110% !important;
    font-size: 7vw;
  }
}

.SlideDescription {
  text-align: center;
  vertical-align: bottom;
  bottom: 80px;
  font-family: "Kanit-Light";
  position: absolute;
  color: #fff;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.4rem;
}

.SlideDescription1 {
  vertical-align: bottom;
  bottom: 24px;
  font-family: "kanit-regular";
  position: absolute;
  color: #fff;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.4rem;
  text-align: center !important;
  width: 100%;
}

.SlideDescription6 {
  vertical-align: bottom;
  bottom: 72px;
  font-family: "kanit-regular";
  position: absolute;
  color: #fff;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.4rem;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .SlideDescription {
    font-family: "kanit-regular";
    position: absolute;
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
    bottom: 100px;
    text-align: center;
    vertical-align: bottom;
    font-size: 1rem;
    width: 100%;
  }

  /* mobile view */

  .SlideDescription1 {
    vertical-align: bottom;
    bottom: 124px;
    font-family: "kanit-regular";
    position: absolute;
    color: #fff;
    /* padding-left: 5%; */
    /* padding-right: 5%; */
    font-size: 1rem;
    text-align: center !important;
    width: 100%;
  }

  .BottomBtn {
    font-family: "kanit-regular";
    position: absolute;
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
    bottom: 140px;
    text-align: center;
    vertical-align: bottom;

    font-size: 1rem;
  }

  /* mobile */
  .SlideDescription6 {
    font-family: "kanit-regular";
    position: absolute;
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
    bottom: 140px;
    text-align: center;
    vertical-align: bottom;
    font-size: 1rem;
    width: 100%;
  }
}

.slideText {
  vertical-align: bottom;
  bottom: 150px;
  position: absolute;
  color: #fff;
  font-size: 3rem;
  font-family: "UnitedSansCondHeavy";
}

@media screen and (min-width: 319px) and (max-width: 425px) {
  .slideText {
    vertical-align: bottom;
    bottom: 237px;
    position: absolute;
    color: #fff;
    font-size: 2rem;
    font-family: "UnitedSansCondHeavy";
  }
}

.text > .description {
  font-size: 18px;
  font-weight: 100;
  vertical-align: bottom;
  font-family: "kanit-regular";
}
/* 
.beats > .section_container > .image {
  order: 2;
  width: 500px;
} */

/* .beats > .text {
  color: #000;
} */

.galaxy_buds > .section_container > .text > .title {
  font-size: 45px;
}

.galaxy_buds > .section_container > .text > .title > span {
  font-weight: 500;
}

.galaxy_buds > .section_container > .text {
  color: #000;
}

.swiper-container-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
  position: absolute;
  left: 95%;
  top: 25%;
  height: 50%;
}
/* hear styling */
.cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #07aa07;
}
.cls-3 {
  fill: #00f000;
}
.cls-4 {
  fill: #06cc06;
}
.cls-5 {
  fill: #9b0e0e;
}
.cls-6 {
  fill: #f00000;
}
.cls-7 {
  fill: #c60a0a;
}
.cls-8 {
  fill: #fff;
}

.company-text {
  fill: #1a1a1a;
}

.dark .company-text,
.transparent .company-text,
.alternate .company-text {
  fill: #fff;
}

.router-link-active {
  /* color: $c-accent; */
  background-color: #fff;
}

header {
  z-index: 10;
  transition: all 0.25s ease;
  background: transparent;
  color: #fff;
  height: 146px;

  z-index: 10;
  position: fixed;
  width: 100%;
}

.fixed {
  top: 0;
  left: 0;
  height: 5.5rem;

  height: 109px;

  color: white;
}

#nav {
  transform: translateY(0.5rem);
  transform: scaleX(1.09);
  width: 95%;
  cursor: pointer;
}
#nav {
  transition: all 0.25s ease;
  transform: translateY(2.25rem);
  cursor: pointer;
}
.dark {
  background: transparent;
  color: #fff;
}
.left a {
  background-color: transparent;
}

b {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: #ae0000; */

  backface-visibility: hidden;
  top: -4.5rem;
  transform: translate3d(0, -9rem, 0);
  transition: transform 0.25s ease;
  will-change: transform;
  z-index: 1;
}
.active {
  transform: translateZ(0);
  transition: transform 375ms ease;
}
/* header.fixed #nav{
      width: 95.23529%;
    } */
#nav {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  width: 75.23529%;
  margin: 1rem auto;
  padding: 0;
  z-index: 1;
  column-gap: 56px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: unset;
    position: relative;
    /* width: 88.23529%; */
    /* margin: 0 auto; */
    padding: 0;
    z-index: 1;
    column-gap: 63px;
  }

  @media screen and (min-width: 768px) and (max-width: 792px) {
    display: flex;
    align-items: center;
    justify-content: unset;
    position: relative;
    /* width: 88.23529%; */
    /* margin: 0 auto; */
    padding: 0;
    z-index: 1;
    column-gap: 190px;
  }
}
/* .logo {
  width: 327px;
} */
.router-link-active.logo {
  background: #fff;
}

.router-link-active {
  /* color: $c-accent; */
  background-color: #fff;
}

@media only screen and (max-width: 792px) {
  header {
    transform: translateY(0rem);
    /* background: $c-accent; */
  }
  header.fixed {
    /* background: $c-accent; */
  }
  .left {
    width: 70%;
    @media (max-width: 425px) and (min-width: 0px) {
      width: 80%;
    }
  }
  .right {
    display: none;
  }
}

@media only screen and (min-width: 792px) {
  header {
    transform: translateY(0rem);
    /* background: $c-accent; */
  }
  header.fixed {
    /* background: $c-accent; */
  }
  .left {
    width: 45%;
  }
  .drawermobile {
    display: none;
  }
}

.flex {
  display: flex;
}
ul {
  list-style: none;
}
.logo_white {
  height: 65px;
}
#nav {
  transform: translateY(0.5rem);
  transform: scaleX(1.09);
  /* width: 95%; */
  cursor: pointer;
}

#intro-animation {
  position: absolute;
  z-index: 99;
  top: 0;
}

#intro-animation-mobile > lottie-animation {
  position: absolute;
  z-index: 5;
  width: 50px;
}

.animate_fade_in {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-delay: 7.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footer-slide {
  background: #ae0000;
  text-align: left;
  font-family: UnitedSansCondHeavy;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-slide a {
  transition: 0.5s ease all;
}

.footer-slide > div {
  justify-content: space-between;
}

.footer-slide h1,
.footer-slide h3 {
  color: white;
}

.footer-slide h1 {
  font-size: 4rem;
  text-decoration: underline;
}

.footer-slide h3 {
  font-style: italic;
  font-family: UnitedSansCond;
  font-size: 2rem;
}

.footer-contact-info {
  font-size: 3.5rem;
  line-height: 4.5rem;
}

.footer-slide a:hover {
  color: #c9c8c8;
}

@media screen and (max-width: 960px) {
  .footer-slide > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-slide h1 {
    font-size: 3rem;
  }

  .footer-slide h2 {
    font-size: 1.5rem;
  }

  .footer-slide > div > div:nth-child(2) {
    margin-top: 2em;
    margin-left: 0;
  }
}

.email {
  position: relative;
  padding-right: 1.2em;
}

.email::after {
  content: "→";
  opacity: 0;
  position: absolute;
  right: 0;
  top: 59%;
  transform: translate(-0.25em, -60%);
  transition: opacity 1s ease, transform 0.25s ease;
}

.email:hover::after {
  opacity: 1;
}

.bg-dark {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  opacity: 0;
  background-color: #c70000;
  transition: all 0.25s ease;
  height: 100vh;
  width: 100%;
}

#lets-chat:hover + .bg-dark {
  opacity: 1;
}

.footer-contact-info:hover + .bg-dark {
  opacity: 1;
}

.strategy-description {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: 0.2rem;
}

.footer-copyright {
  width: 100%;
  /*border: 2px solid;*/
  height: 45vh;
  padding-bottom: 3em;
  align-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (min-width: 0px) and (max-width: 768px) {
    justify-content: left;
  }
}

.footer-copyright p {
  font-size: 0.8em;
  font-family: "kanit-regular";
  padding: 0 0.5em;
}

.footer-slide .flex-align-right {
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .footer-copyright {
    flex-direction: column;
    align-content: flex-start;
    justify-content: end !important;
    height: auto;
    padding-bottom: 0.5em;
    display: none;
  }

  #countries-list {
    display: none;
  }

  .footer-slide {
    justify-content: center;
  }
}

@media screen and (max-width: 540px) {
  .footer-slide h3 {
    font-size: 1.5rem;
  }

  .footer-slide h1 {
    font-size: 2rem;
  }

  .footer-slide .footer-contact-info {
    font-size: 1.1rem;
  }
}

#social-media-icons {
  column-gap: 1em;
  width: 80%;
  margin-top: 2em;
}

#social-media-icons svg {
  max-width: 32px;
  transition: 1s ease all;
}

#social-media-icons svg:hover {
  transform: scale(1.15);
}

@media screen and (max-width: 768px) {
  #social-media-icons {
    flex-direction: row;
  }
}

/* .scroll-arrow-container #scroll-down-arrow {
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 2em;
  transform: scale(1.8);
}

.scroll-arrow-container a {
  font-family: 'Kanit-Light';
  letter-spacing: 0.2em;
  font-size: 0.8em;
  text-transform: uppercase;
}

#more-about-us {
  max-width: 100%;
  height: 100vh;
  background: darkblue;
}

.swiper-container {
  position: relative !important;
} */
</style>

<template>
  <!-- SEO Tags -->
  <teleport to="head">
    <meta name="description" :content="Seo.Description" />
    <meta name="keywords" :content="Seo.Keywords" />
    <link rel="alternate" hreflang="x-default" :href="Seo.HreflangDefault" />
    <link rel="alternate" hreflang="en-US" :href="Seo.HreflangCountry" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="Seo.OpenGraph.Title" />
    <meta property="og:site_name" :content="Seo.OpenGraph.SiteName" />
    <meta property="og:url" :content="Seo.OpenGraph.Url" />
    <meta property="og:description" :content="Seo.OpenGraph.Description" />
    <meta property="og:type" :content="Seo.OpenGraph.Website" />
    <meta property="og:image" :content="Seo.OpenGraph.Image" />
    <!-- ./Open Graph Tags -->

    <!-- Twitter Card Tags -->
    <meta name="twitter:card" :content="Seo.TwitterCard.Card" />
    <meta name="twitter:site" :content="Seo.TwitterCard.Site" />
    <meta name="twitter:title" :content="Seo.TwitterCard.Title" />
    <meta name="twitter:description" :content="Seo.TwitterCard.Description" />
    <meta name="twitter:image" :content="Seo.TwitterCard.Image" />
    <!-- ./Twitter Card Tags -->
  </teleport>
  <!-- ./SEO Tags -->

  <div>
    <!-- Main homepage animation -->
    <teleport to="#main-animation-wrapper">
      <lottie-animation
        id="intro-animation"
        class="SliderCenterImg"
        path="assets/animation.json"
        loop="false"
        speed="0.8"
        @AnimControl="handleAnimation"
        v-if="!isMobile() && AnimCheck == null && animationVisible == true"
      />
    </teleport>

    <swiper
      :effect="'fade'"
      :loop="false"
      :pagination="!animationVisible && swiperPaginationObject"
      :direction="'vertical'"
      :slidesPerView="1"
      :spaceBetween="0"
      :mousewheel="true"
      class="mySwiper"
    >
      <swiper-slide>
        <div class="ari_pods sections">
          <div class="section_container flex">
            <div id="intro-animation-mobile" class="SliderCenterImg">
              <lottie-animation
                path="assets/animation_mobile_box.json"
                loop="false"
                v-if="isMobile() && !AnimCheck"
                @AnimControl="handleAnimation"
              />
            </div>
            <div
              class="ReactBox"
              v-if="!isMobile() || AnimCheck"
              v-bind:class="{ animate_fade_in: !AnimCheck }"
            ></div>
            <p class="FirstSlideTitle" :class="{ animate_fade_in: !AnimCheck }">
              {{ content.Slider1.Title[0] }}<br />{{ content.Slider1.Title[1] }}
            </p>

            <p
              class="SlideDescription"
              :class="{ animate_fade_in: !AnimCheck }"
            >
              {{ content.Slider1.Description }}
            </p>

            <!--<p class="SlideDescription1" :class="{animate_fade_in: !AnimCheck}">            
            <router-link to="/contact"  >
              {{ content.Slider1.ButtonText }}</router-link
            >
          </p>-->
          </div>
        </div>
      </swiper-slide>

      <!-- <swiper-slide>
        <div class="beats sections">
          <div class="section_container flex">
            <img
              src="@/assets/images/webp/excellence.webp"
              alt=""
              class="SliderCenterImg"
            />
            <div class="image">
              <p class="SlideTitle">{{ content.Slider2.Title }}</p>
            </div>

            <p class="SlideDescription">
              {{ content.Slider2.Description }}
            </p>
          </div>
        </div>
      </swiper-slide> -->

      <!-- <swiper-slide>
        <div class="apple_watch sections">
          <div class="section_container flex">
            <img
              src="@/assets/images/webp/slide4-img.webp"
              alt=""
              class="SliderCenterImg2"
            />
            <div class="image">
              <p class="SlideTitle">{{ content.Slider3.Title }}</p>
            </div>

            <p class="SlideDescription">
              {{ content.Slider3.Description }}
            </p>
          </div>
        </div>
      </swiper-slide> -->

      <swiper-slide>
        <div class="slide6 sections">
          <div class="section_container flex">
            <img
              src="@/assets/images/webp/slide6-img-min.webp"
              alt=""
              class="SliderCenterImg2"
            />
            <div class="image">
              <p class="SlideTitle">
                {{ content.Slider5.Title }}
              </p>
            </div>

            <p class="SlideDescription strategy-description">
              {{ content.Slider5.Description }}
            </p>
          </div>

          <!-- </div> -->
        </div>
      </swiper-slide>

      <!-- <section class="paral" style="height : 100vh">
        <div class="container"></div>
      </section> -->

      <swiper-slide>
        <div class="slide8 sections">
          <div class="section_container flex">
            <!-- <img
            src="@/assets/images/webp/slide8-img-min.webp"
            alt=""
            class="SliderCenterImg2"
          /> -->
            <div class="image">
              <p class="SlideTitle">
                {{ content.Slider8.Title }}
              </p>
            </div>

            <p class="SlideDescription">
              {{ content.Slider8.Description }}
              <br /><br />
              <span class="strategy-description">{{
                content.Slider8.Description2
              }}</span>
            </p>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="slide9 sections">
          <div class="section_container flex">
            <img
              src="@/assets/images/webp/slide9-img.webp"
              alt=""
              class="SliderCenterImg2"
            />
            <div class="image">
              <p class="SlideTitle">
                {{ content.Slider9.Title }}
              </p>
            </div>

            <p class="SlideDescription strategy-description">
              {{ content.Slider9.Description }}
            </p>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="galaxy_buds sections">
          <div class="section_container flex">
            <!-- <Box class="SliderCenterImg2" /> -->
            <div class="image">
              <p class="SlideTitle">
                <!-- <span style="color: #000; "> -->

                {{ content.Slider4.Title }}

                <!-- <span
                  class="blue-text"
                  style="color: #000; "
                  >{{ content.Slider4.Title[1] }}</span
                >
                <span
                  class="blue-text"
                  style="color: #000;"
                >
                </span>
                {{ content.Slider4.Title[2] }}

                  <span
                  class="blue-text"
                  style="color: #000;"
                >
                </span> -->
                <!-- {{ content.Slider4.Title[3] }} -->

                <!-- </span> -->
              </p>
            </div>

            <p class="SlideDescription strategy-description">
              {{ content.Slider4.Description }}
            </p>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="sections footer-slide">
          <div class="section_container flex chat-sec">
            <div class="chatSection">
              <!-- <h3>{{ content.FooterHeading1 }}</h3> -->
              <h1 id="lets-chat">
                <router-link to="/contact" class="email">
                  {{ content.FooterHeading2 }}</router-link
                >
              </h1>
              <div class="bg-dark"></div>
              <!-- <div id="social-media-icons" class="flex">
                <a href="https://www.facebook.com/thegapdynamics"
                  ><Facebook viewBox="0 0 40 50"
                /></a>
                <a href="https://www.instagram.com/thegapdynamics/"
                  ><Instagram
                /></a>
                <a href="https://twitter.com/thegapdynamics"><Twitter /></a>
                <a href="https://www.linkedin.com/company/thegapdynamics"
                  ><Linkedin viewBox="0 0 20 30"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UCRmHVzVqWVCKT8fFDqtOglg"
                  ><Youtube viewBox="0 0 50 15"
                /></a>
              </div> -->
            </div>

            <div class="footer-nav">
              <div class="dark-sec">
                <div>
                  <ul class="footer-list">
                    <li>
                      <router-link
                        :to="{
                          name: 'Services',
                          query: { section: 'digitalTransformation' },
                        }"
                        >{{ content.digitalText }}</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'Services',
                          query: { section: 'blockchain' },
                        }"
                        >{{ content.blockchainText }}</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'Services', query: { section: 'web' } }"
                        >{{ content.webText }}</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'Services', query: { section: 'appDev' } }"
                        >{{ content.appText }}</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'Services', query: { section: 'design' } }"
                        >{{ content.designText }}</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'Services',
                          query: { section: 'digitalMarketing' },
                        }"
                        >{{ content.marketingText }}</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div>
                  <ul class="footer-list">
                    <li>
                      <router-link :to="{ name: 'Services' }">
                        {{ content.serviceText }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'Projects' }">{{
                        content.projectsText
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'About' }">{{
                        content.teamText
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'Jobs' }">{{
                        content.careersText
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'Blog' }">{{
                        content.blogText
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'Contact' }">{{
                        content.contactText
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="establish-sec">
                <p>
                  {{ content.establishText }}
                </p>
                <h2 class="footer-contact-info">
                  <span v-if="siteLanguage == 'GR' || localClientIp != 'PK'">
                    <a
                      title="Email"
                      class="mobRes"
                      href="mailto:hello@gapdynamics.de"
                      >hello@gapdynamics.de</a
                    >
                  </span>

                  <span v-else>
                    <!-- <a title="Call us" class="mobRes" href="tel:+4917662775566"
                    >hello@gapdynamics.de</a
                  >
                  <br /> -->

                    <a
                      title="Email"
                      class="mobRes"
                      href="mailto:hello@gap-dynamics.com"
                      >hello@gap-dynamics.com
                    </a>
                  </span>
                </h2>
                <p v-if="siteLanguage == 'GR'">
                  <router-link
                    :to="{ name: 'Impressum' }"
                    style="text-decoration: none"
                  >
                    {{ content.PrivacyPolicy }}
                  </router-link>
                </p>
                <p v-else>
                  <router-link
                    :to="{ name: 'privacy-policy' }"
                    style="text-decoration: none"
                  >
                    {{ content.PrivacyPolicy }}
                  </router-link>
                </p>
              </div>
              <div class="bg-dark"></div>
            </div>
          </div>

          <!-- <div class="flex scroll-arrow-container">
          <div>
            <DoubleDownArrow id="scroll-down-arrow" />
            <br /> 
            <a href="#more-about-us"  v-smooth-scroll="{ duration: 1000}"  class="header-btn">{{content.ScrollButtonText}}</a>
          </div>
        </div> -->

          <!-- <div
            class="footer-copyright"
            :class="siteLanguage == 'GR' && 'flex-align-right'"
          >
            <div id="countries-list" class="flex" v-if="siteLanguage == 'EN'"> -->
          <!-- <p>Germany</p>
              <p>United Kingdom</p>
              <p>Pakistan</p> -->
          <!-- </div> -->
        </div>
        <!-- </div> -->
      </swiper-slide>
    </swiper>

    <!-- <div id="more-about-us" class="container">
    <h1>Testing</h1>
  </div> -->
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";
import "./style3.scss";

// import Box from "../assets/images/slide4-box.svg";

import "swiper/components/scrollbar/scrollbar.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
// import "swiper/components/pagination/pagination.min.css";
// import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { EffectFade, Mousewheel, Pagination } from "swiper/core";

// Import language and SEO strings
import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";

// install Swiper modules

SwiperCore.use([Mousewheel, Pagination, EffectFade]);

// Social media icons
// import Twitter from "@/assets/icons/twitter.svg";
// import Linkedin from "@/assets/icons/linkedin.svg";
// import Instagram from "@/assets/icons/instagram.svg";
// import Facebook from "@/assets/icons/facebook.svg";
// import Youtube from "@/assets/icons/youtube.svg";

// Other icons
// import DoubleDownArrow from "@/assets/icons/double_down_arrow.svg";

export default {
  components: {
    Swiper,
    SwiperSlide,
    // Twitter,
    // Linkedin,
    // Instagram,
    // Facebook,
    // Youtube,
    // DoubleDownArrow
  },
  data() {
    return {
      content: [],
      swiperPaginationObject: {
        clickable: true,
        type: "progressbar",
      },
      Seo: {},
      AnimCheck: this.isMobile() ? true : localStorage.getItem("Load"),
      siteLanguage: localStorage.getItem("siteLanguage"),
      animationVisible: true,
      localClientIp: localStorage.getItem("localClientIp"),
    };
  },

  async created() {
    localStorage.getItem("siteLanguage") === "GR"
      ? ((this.content = LanguageStrings.gr.HomePage),
        (this.Seo = SeoStrings.gr.Home))
      : ((this.content = LanguageStrings.en.HomePage),
        (this.Seo = SeoStrings.en.Home));

    document.querySelector("head title").innerText = this.content.MetaTitle;
    if (!this.AnimCheck) {
      // setTimeout(() => (this.animationVisible = false), 8500);
    } else {
      this.animationVisible = false;
    }
  },

  mounted() {
    document.body.addEventListener(
      "touchmove",
      (ev) => {
        if (ev.touches.length > 1) {
          ev.preventDefault();
          ev.stopImmediatePropagation();
        }
      },
      true
    );
    // document.querySelector('#intro-animation').addEventListener('complete', () => {
    //   alert('Complete')
    // })
  },
  methods: {
    isMobile() {
      if (screen.width < 768) {
        return true;
      } else {
        return false;
      }
    },
    handleAnimation(e) {
      e.onComplete = () => this.animationVisible = false;
    }
  },
};
</script>
