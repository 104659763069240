<template>
  <div
    class="element"
    :class="animationPaused && 'animation-paused'"
    v-show="elementVisible"
  >
    <div class="img-tag m-auto">
      <Logo />
    </div>
  </div>
  <!--<div id="anim-bg" :class="animationPaused && 'animation-paused'" v-show="elementVisible"></div>-->
</template>

<script>
import "./style.scss";
import Logo from "@/assets/imgs/company-logo-03.svg";

// import $ from 'jquery'
export default {
  components: {
    Logo,
  },
  data() {
    return {
      elementVisible: true,
      animationPaused: false,
    };
  },

  created() {
    setTimeout(() => (this.animationPaused = true), 400);
  },

  mounted() {
    // setTimeout(() => {
    //   this.animationPaused = false;
    // }, 1000);
  },
};
</script>
