<style scoped>
.u-header__toggle {
  margin: -1.5rem 0 0 -0.8125rem;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.m-hamburger {
  display: inline-block;
  height: 3rem;
  width: 3rem;
}
.m-hamburger,
abbr {
  text-decoration: none;
}

.m-hamburger__inner {
  -webkit-animation: hamburger-inactive 0.5s ease both;
  animation: hamburger-inactive 0.5s ease both;
  display: block;
  height: 1.375rem;
  margin: 0.8125rem;
  position: relative;
  width: 1.375rem;
}




.m-hamburger__menu--1 {
  -webkit-animation-name: hamburger-menu-inactive-1;
  animation-name: hamburger-menu-inactive-1;
}
.m-hamburger__menu {
  -webkit-animation: hamburger-menu-inactive 0.5s ease both;
  animation: hamburger-menu-inactive 0.5s ease both;
  background: currentColor;
  display: block;
  height: 2px;
  margin: -1px 0 0;
  position: absolute;
  top: 50%;
  width: 100%;
  



  


}

.m-hamburger__menu--2 {
  -webkit-animation-name: hamburger-menu-inactive-2;
  animation-name: hamburger-menu-inactive-2;
  margin-top: 13px;
}

.u-header__toggle-close {
  margin: -0.5rem 0 0 -0.8125rem;
  pointer-events: auto;
  position: absolute;
  /* top: 50%; */
  z-index: 2;
  transform: rotate(90deg);
  transition: transform 1s linear;
  color: #ffffff;
}
.drawer-text {
  height: 30px;
  background-color: black;
  color: #ffffff;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 60px;
}
</style>

<template>
  <div class="drawermobile">
    <a-radio-group
      style="margin-right: 8px"
      :default-value="placement"
      @change="onChange"
    >
      <!-- <a-radio value="top">
        top
      </a-radio> -->
    </a-radio-group>
    <!-- <a-button style="border: 1px solid #ae0000;" @click="showDrawer">
      <i class="fa fa-bars" aria-hidden="true" style="color: black;"></i>
    </a-button> -->

    <button
      aria-label="Menu"
      class="u-header__toggle m-hamburger"
      @click="showDrawer"
    >
      <span aria-hidden="true" class="m-hamburger__inner"
        ><span class="m-hamburger__menu m-hamburger__menu--1"></span>
        <span class="m-hamburger__menu m-hamburger__menu--2"></span
      ></span>
      <!---->
    </button>

    <a-drawer
      :placement="placement"
      :closable="true"
      :visible="visible"
      @close="onClose"
    >
      <!-- <div @click="onClose" class="closesignicon">
        <div><i class="fas fa-times cross-faicon"></i></div>
      </div> -->

      <!-- <div v-if="(this.visible = true)"> -->
        <button
          aria-label="Menu"
          class="closesignicon u-header__toggle-close m-hamburger"
          @click="onClose"
        >
          <span aria-hidden="true" class="m-hamburger__inner"
            ><span class="m-hamburger__menu m-hamburger__menu--1"></span>
            <span class="m-hamburger__menu m-hamburger__menu--2"></span
          ></span>
          <!---->
        </button>
      <!-- </div> -->
      <!-- <div v-else>
        <h1>bp</h1>
      </div> -->
     
      <p class="drawer-text">
        <router-link :to="{ name: 'Services' }" @click="onClose"
          >{{ content.Services }}
        </router-link>
      </p>
      <p class="drawer-text">
        <router-link :to="{ name: 'Projects' }" @click="onClose"
          >{{content.Projects}}</router-link
        >
      </p>
      <p class="drawer-text">
        <router-link
          @click="onClose"
          v-bind:class="{
            routerLinkActive:
              getRoutePath === 'About' || getRoutePath === 'TeamDetails',
          }"
          :to="{ name: 'About' }"
          >{{ content.Team }}</router-link
        >
      </p>

          <p class="drawer-text">
        <router-link
          @click="onClose"
          v-bind:class="{
            routerLinkActive:
               getRoutePath === 'Jobs' || getRoutePath === 'JobDetails',
          }"
          :to="{ name: 'Jobs' }"
          >{{ content.Jobs }}</router-link
        >
      </p>

      <p class="drawer-text">
      <a href="https://blogs.gap-dynamics.com/"   rel="noopener noreferrer" @click="onClose">{{ content.Blog }}</a>
      </p>
      <p class="drawer-text">
        <router-link :to="{ name: 'Contact' }" @click="onClose"
          >{{ content.Contact }}</router-link
        >
      </p>
    </a-drawer>
  </div>
</template>
<script>

import { LanguageStrings } from "@/languages";


export default {

  


  data() {
    return {
      visible: false,
      placement: "left",
           content: null,
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },

    onChange(e) {
      this.placement = e.target.value;
    },
  },

  created(){
    localStorage.getItem("siteLanguage") === "GR"
      ? (this.content = LanguageStrings.gr.Menu)
      : (this.content = LanguageStrings.en.Menu);
  }
};
</script>
